import * as React from "react"
import { Link } from "gatsby"

import 'uikit/dist/css/uikit.css'
//import UIkit from 'uikit'
import Layout from "../layouts"



// markup
const NotFoundPage = () => {
  return (
    <Layout SEOTitle={"404 Not Found | Free Motor Legal"} darkThemeNav={true}>
      <div className="noPageFound">
        <div className="uk-text-center errorDetails">
          <h1>404</h1>
          <h3><b>Sorry, we can't find that page</b></h3>
          {/* <h4>Please try one of the following</h4> */}
          <div className="ctas" >
            <Link to="/" className="returnHomeBtn">Return home</Link>
            {/* <Link to="/products" className="getAQuote">Get a quote</Link> */}
           
          </div>


        </div>





      </div>

    </Layout>
  )
}

export default NotFoundPage
